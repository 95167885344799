import api from '@/api/testimonials'

export default {
	data () {
		return {
			valid: false,
			formData: {
				name: '',
				title: '',
				text: '',
				avatar: null
			}
		}
	},
	methods: {
		create () {
			if (this.$refs.form.validate()) {
				let data = new FormData()
				Object.keys(this.formData).map(key => {
					data.append(key, this.formData[key])
				})

				api.create(this, data)
					.then(() => {
						this.$router.push({name: 'Testimonials'})
					})
			}
		}
	}
}
